import React, { useState } from 'react'
import { dateMap } from '../DateHelper'

export const useEffectiveDate = (initialMonth, initialYear) => {
  const [effectiveMonth, setEffectiveMonth] = useState(initialMonth || new Date().getMonth() + 1)
  const [effectiveYear, setEffectiveYear] = useState(initialYear || new Date().getFullYear())

  const effectiveDatePicker = (
    <>
      {/* Month Selector */}
      <select className="no-select-2" value={effectiveMonth} onChange={(e) => setEffectiveMonth(parseInt(e.target.value))}>
        {Object.entries(dateMap).map(([i, month]) => (
          <option key={i} value={i}>
            {month}
          </option>
        ))}
      </select>

      {/* Year Selector */}
      <select className="no-select-2" value={effectiveYear} onChange={(e) => setEffectiveYear(e.target.value)}>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
      </select>
    </>
  )

  return {
    effectiveYear,
    effectiveMonth,
    setEffectiveMonth,
    setEffectiveYear,
    effectiveDatePicker,
  }
}
