import React, { useEffect, useRef, useContext } from 'react'
import Badge from '../Badge'

import { MordorContext } from './MordorProgress'
import { MordorUploadModalWithButton } from './MordorUploadModal'

const canCrossCheck = () => {
  return true
  const { administrator, role } = window.react_state.current_user
  return administrator || role == 'executive'
}

export const MordorDocumentTable = ({ requiredUploads, timeline }) => {
  useEffect(() => {
    $('.react-mordor-doc-table').DataTable()
  })

  const { effectiveMonth, effectiveYear } = useContext(MordorContext)

  const columns = [
    'Reference Source',
    ...(canCrossCheck() ? ['Run Crosscheck'] : []),
    'Review Question',
    'Associated document',
    'Associated document uploaded?',
    'Report Method',
    'Document Required',
    'Policies and Procedures Required',
    'HUD Citations',
  ]
  return (
    <div>
      <table className="table table-sm table-hover table-bordered react-mordor-doc-table">
        <thead className="tead-light">
          <tr>
            {columns.map((col) => (
              <th key={col}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {requiredUploads.map((ru) => (
            <RequiredUploadRow key={ru.id + '#' + effectiveMonth + effectiveYear} data={ru} timeline={timeline} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const RequiredUploadRow = ({ data, timeline }) => {
  const { binderId, showCrossCheck, canUploadQuestion } = useContext(MordorContext)

  const mordorQuestion = data
  const partyRef = useRef()

  const abi = mordorQuestion.associated_binder_item ? (
    <a href={mordorQuestion.associated_binder_item.document.url}>{mordorQuestion.associated_document}</a>
  ) : (
    mordorQuestion.associated_document
  )

  return (
    <tr>
      <td>
        <a data-toggle="modal" data-target={`#modal-mordor-question-${mordorQuestion.id}`} className="auto-finding-data" href="">
          {mordorQuestion.reference_source}
        </a>
      </td>
      {canCrossCheck() && (
        <td>
          <a className="btn btn-primary" onClick={() => showCrossCheck(mordorQuestion.id)}>
            Crosscheck Doc
            <i className="fas fa-solid fa-check ml-1" />
          </a>
        </td>
      )}
      <td>{mordorQuestion.review_question}</td>
      <td>{abi}</td>
      <td>
        <div ref={partyRef}>
          {mordorQuestion.associated_binder_item ? (
            <Badge success>Yes</Badge>
          ) : canUploadQuestion ? (
            <MordorUploadModalWithButton mordorQuestion={mordorQuestion} timeline={timeline} partyRef={partyRef} />
          ) : (
            <span />
          )}
        </div>
      </td>
      <td>{mordorQuestion.report_method}</td>
      <td>
        {mordorQuestion.document_required === 'Yes' && <Badge danger>Yes</Badge>}
        {mordorQuestion.document_required === 'Conditional' && <Badge warning>Conditional</Badge>}
        {mordorQuestion.document_required === 'No' && <Badge success>No</Badge>}
      </td>
      <td>{mordorQuestion.policies_and_procedures_required == 'Yes' ? <Badge warning>Yes</Badge> : <Badge success>No</Badge>}</td>
      <td>{mordorQuestion.hud_citations}</td>
    </tr>
  )
}
