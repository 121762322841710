import React from 'react'

const Badge = ({ danger, warning, success, children }) => {
  let classNames = 'badge'
  if (danger) classNames += ' badge-danger'
  if (warning) classNames += ' badge-warning'
  if (success) classNames += ' badge-success'

  return <span className={classNames}>{children}</span>
}

export default Badge
