import React, { useState, useEffect } from 'react'
import { KismetAccordion } from '../KismetAccordion'
import { sortReportsByStatus } from './mordor_helper'
import { ProgressBar } from '../ProgressBar'
import { MordorDocumentTable } from './MordorDocumentTable'
import { toast } from 'react-toastify/dist/react-toastify'
import { MordorDocumentCrossCheck } from './MordorDocumentCrossCheck'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import { useEffectiveDate } from '../hooks/useEffectiveDate'
import { HOC } from '../HOC'

export const MordorContext = React.createContext({
  updateBinderItem: () => {},
  showCrossCheck: () => {},
  binderId: null,
  effectiveMonth: null,
  effectiveYear: null,
  canUploadQuestion: null,
})

export const MordorProgress = ({ binderId, expandable = false, canUploadQuestion }) => {
  const { effectiveYear, effectiveMonth, effectiveDatePicker } = useEffectiveDate()

  const [reportResults, setReportResults] = useState(undefined)
  const [error, setError] = useState(null)
  const [showResolved, setShowResolved] = useState(true)
  const [crossCheckQuestionId, setCrossCheckQuestionId] = useState(undefined)

  const fetchBinder = async () => {
    const baseUrl = window.react_state.base_url || ''
    const req = axios
      .get(`${baseUrl}/api/mordor_binders/${binderId}`, {
        params: { effective_year: effectiveYear, effective_month: effectiveMonth },
        headers: { Authorization: `Bearer ${window.react_state.jwt}` },
      })
      .catch(setError)

    const result = await toast.promise(req, { pending: 'Working...' })

    setReportResults(result.data)
  }

  const showCrossCheck = (questionId) => {
    setCrossCheckQuestionId(questionId)
  }

  useEffect(() => {
    fetchBinder()
  }, [effectiveYear, effectiveMonth])

  if (error) {
    return <div>Error: {error.message}</div>
  }

  function updateBinderItem(qid, timeline, item) {
    const newReportResults = { ...reportResults }
    newReportResults[timeline.toLowerCase() + '_results'].find((q) => q.id == qid).associated_binder_item = item
    setReportResults(newReportResults)
  }

  const sortedReports = sortReportsByStatus(reportResults)

  return (
    <div className="justify-content-center">
      <Modal size="lg" show={!!crossCheckQuestionId} onHide={() => setCrossCheckQuestionId('')}>
        <Modal.Header closeButton>
          <Modal.Title>Document Crosscheck</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {crossCheckQuestionId && (
            <MordorDocumentCrossCheck
              questionId={crossCheckQuestionId}
              initialEffectiveYear={effectiveYear}
              initialEffectiveMonth={effectiveMonth}
              canUploadQuestion={canUploadQuestion}
            />
          )}
        </Modal.Body>
      </Modal>

      {effectiveDatePicker}

      <br />

      {/* Checkbox to toggle showResolved*/}
      {expandable && (
        <label>
          <input type="checkbox" checked={showResolved} onChange={(e) => setShowResolved(e.target.checked)} />
          Show Resolved Documents
        </label>
      )}

      <MordorContext.Provider
        value={{
          updateBinderItem,
          binderId,
          effectiveMonth,
          effectiveYear,
          showCrossCheck,
          canUploadQuestion,
        }}
      >
        {sortedReports &&
          sortedReports.map(({ name, completedDocs, allDocs, uncompletedDocs, percentage, requiredTotal, requiredCompleted }, index) => (
            <KismetAccordion
              header={
                <div className="d-flex my-2 p-2 rounded border">
                  <span style={{ width: '25%' }}>{name}</span>
                  <ProgressBar percent={percentage} rightText={`${requiredCompleted}/${requiredTotal} (${Math.round(percentage * 100)}%)`} animated />
                </div>
              }
              body={<MordorDocumentTable requiredUploads={showResolved ? allDocs : uncompletedDocs} timeline={name} />}
              expandable={expandable}
              key={index}
            />
          ))}
      </MordorContext.Provider>
    </div>
  )
}

export default HOC(MordorProgress)
