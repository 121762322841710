import React, { useState } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import { useKismetRequest } from './useKismetRequest'

export const usePropertySelector = (defaultValue) => {
  console.log('usePropertySelector defaultValue', defaultValue)
  const { data: allProperties } = useKismetRequest({
    url: '/api/properties',
    autoFetch: true,
    defaultValue: [],
  })

  defaultValue = defaultValue || []
  const [selectedProperties, setSelectedProperties] = useState(defaultValue)

  const propertySelector = (
    <div>
      <MultiSelect
        value={selectedProperties}
        onChange={(e) => setSelectedProperties(e.value)}
        options={allProperties}
        optionLabel="name"
        filter
        placeholder="Select Properties"
        maxSelectedLabels={5}
        // display="chip"
        className="w-full md:w-20rem"
      />
    </div>
  )

  return { selectedProperties, propertySelector, setSelectedProperties }
}
