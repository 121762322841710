import React from 'react'

// Example columnSettings
// const columnSettings = [
//     {
//       name: "Effective Date",
//       key: "effective_date",
//       formatter: (value) => prettyPrintDate(value),
//       summable: false,
//     },
//     {
//       name: "Rent Billed",
//       key: "rent_billed",
//       summable: true,
//     },
//     {
//       name: "Subsidy Billed",
//       key: "subsidy_billed",
//       summable: true,
//     },
//   ];

export const SlickTable = ({ columnSettings, rows }) => {
  const requiresSum = columnSettings.some((col) => col.summable)

  const calcSummables = () => {
    return columnSettings.map((col) => {
      if (col.summable) {
        return rows.reduce((acc, row) => acc + row[col.key], 0)
      }
      return null
    })
  }

  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          {columnSettings.map((col) => (
            <th>{col.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {requiresSum && (
          <tr className="table-success">
            {calcSummables().map((sum) => {
              return <td>{sum}</td>
            })}
          </tr>
        )}

        {rows.map((row) => (
          <tr>
            {columnSettings.map((col) => {
              const fmt = col.formatter || ((x) => x)
              return <td>{fmt(row[col.key])}</td>
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default SlickTable
