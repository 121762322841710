import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify/dist/react-toastify'

// example usage:
// const { data, error, loading, fetch } = useKismetRequest({
//     url: "/api/washing_machine/unit_gross_potential_activity",
//     params: { property_name: propertyName, unit_number: unitNumber },
//     autoFetch: true,
// });

export const useKismetRequest = ({ url, params, autoFetch, defaultValue }) => {
  const [data, setData] = useState(defaultValue)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  // autofetch true by default
  if (autoFetch === undefined) {
    autoFetch = true
  }

  if (!window.react_state) {
    throw new Error('You must inject react state!')
  }

  const baseUrl = window.react_state.base_url || ''

  const fetch = async () => {
    setLoading(true)
    const req = axios
      .get(baseUrl + url, {
        params,
        headers: { Authorization: `Bearer ${window.react_state.jwt}` },
      })
      .catch(setError)

    const result = await toast.promise(req, { pending: 'Working...' })

    setData(result.data)
    setLoading(false)
  }

  useEffect(() => {
    console.log('Effect triggered')
    if (autoFetch) {
      fetch()
    }
  }, [url, params])

  return {
    data,
    error,
    loading,
    fetch,
  }
}
