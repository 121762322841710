import React, { useMemo } from 'react'
import { useEffectiveDate } from '../hooks/useEffectiveDate'
import { useKismetRequest } from '../hooks/useKismetRequest'

export const MordorUnitVacancy = ({ propertyName }) => {
  const { effectiveYear, effectiveMonth, effectiveDatePicker } = useEffectiveDate()

  const { data: reportRows } = useKismetRequest({
    url: '/api/washing_machine/property_mordor_report_rows',
    params: useMemo(
      () => ({
        effective_year: effectiveYear,
        effective_month: effectiveMonth,
        property_names: [propertyName],
        doc_ref_id: 'dbf5bdef7064475ea38c2e3f3303f1c0',
      }),
      [effectiveYear, effectiveMonth, propertyName]
    ),
    autoFetch: true,
    defaultValue: [],
  })

  return (
    <div>
      <h5>Operating Software Statistics</h5>
      {effectiveDatePicker}
      <p>Vacant Units Reported: {reportRows.length}</p>
    </div>
  )
}

export default MordorUnitVacancy
