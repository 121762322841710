import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

export const useUuid = () => {
  const [uuid, setUuid] = React.useState(null)

  useEffect(() => {
    setUuid(uuidv4())
  }, [])

  return uuid
}
