import React, { useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { deblobReportRows } from './mordor_helper'
import { useKismetRequest } from '../hooks/useKismetRequest'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import HOC from '../HOC'

const UGPModal = ({ unitNumber, propertyName }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const {
    data: gpRows,
    error,
    loading,
    fetch: fetchGrossData,
  } = useKismetRequest({
    url: '/api/washing_machine/property_mordor_report_rows',
    params: { property_names: [propertyName], unit_number: unitNumber, doc_ref_id: '2cbd891f28fc40bfa0cdcde2dca3099e' },
    autoFetch: false,
    defaultValue: [],
  })

  const openModal = () => {
    fetchGrossData()
    setModalOpen(true)
  }

  const sumRow = useMemo(() => {
    return {
      rent_billed: deblobReportRows(gpRows).reduce((acc, row) => acc + row.rent_billed, 0),
      subsidy_billed: deblobReportRows(gpRows).reduce((acc, row) => acc + row.subsidy_billed, 0),
      utility_reimbursement: deblobReportRows(gpRows).reduce((acc, row) => acc + row.utility_reimbursement, 0),
      total_billed_to_ar: deblobReportRows(gpRows).reduce((acc, row) => acc + row.total_billed_to_ar, 0),
      overage_excess_rent: deblobReportRows(gpRows).reduce((acc, row) => acc + row.overage_excess_rent, 0),
      lost_rent: deblobReportRows(gpRows).reduce((acc, row) => acc + row.lost_rent, 0),
      vacancy_expense: deblobReportRows(gpRows).reduce((acc, row) => acc + row.vacancy_expense, 0),
      total_billed: deblobReportRows(gpRows).reduce((acc, row) => acc + row.total_billed, 0),
      unit_gross_potential: deblobReportRows(gpRows).reduce((acc, row) => acc + row.unit_gross_potential, 0),
      variance: deblobReportRows(gpRows).reduce((acc, row) => acc + row.variance, 0),
      concessions: deblobReportRows(gpRows).reduce((acc, row) => acc + row.concessions, 0),
      total_billed_after_concessions: deblobReportRows(gpRows).reduce((acc, row) => acc + row.total_billed_after_concessions, 0),
    }
  }, [gpRows])

  return (
    <div>
      <a className="btn btn-primary" onClick={openModal}>
        View Gross
        <i className="fas fa-solid fa-check ml-1" />
      </a>
      <Modal size="xl" show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Unit Gross Potential History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable value={deblobReportRows(gpRows)} frozenValue={[sumRow]} scrollable>
            <Column field="effective_date" header="Effective Date" />
            <Column field="rent_billed" header="Rent Billed" />
            <Column field="subsidy_billed" header="Subsidy Billed" />
            <Column field="utility_reimbursement" header="Utility Reimbursement" />
            <Column field="total_billed_to_ar" header="Total Billed To AR" />
            <Column field="overage_excess_rent" header="Overage Excess Rent" />
            <Column field="lost_rent" header="Lost Rent" />
            <Column field="vacancy_expense" header="Vacancy Expense" />
            <Column field="total_billed" header="Total Billed" />
            <Column field="unit_gross_potential" header="Unit Gross Potential" />
            <Column field="variance" header="Variance" />
            <Column field="concessions" header="Concessions" />
            <Column field="total_billed_after_concessions" header="Total Billed After Concessions" />
          </DataTable>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default HOC(UGPModal)
