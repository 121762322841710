import React, { useState, useContext } from 'react'
import { MordorContext } from './MordorProgress'
import Modal from 'react-bootstrap/Modal'
import partyjs from 'party-js'
import KismetDropzone from '../DropZone'
import Badge from '../Badge'
import { toast } from 'react-toastify/dist/react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const MordorUploadModalWithButton = ({ mordorQuestion, partyRef, timeline }) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  return (
    <>
      <a className="btn btn-primary" onClick={() => setUploadModalOpen(true)}>
        Upload Doc
        <i className="fas fa-solid fa-check ml-1" />
      </a>
      <MordorUploadModal
        mordorQuestion={mordorQuestion}
        partyRef={partyRef}
        timeline={timeline}
        uploadModalOpen={uploadModalOpen}
        setUploadModalOpen={setUploadModalOpen}
      />
    </>
  )
}

export const MordorUploadModal = ({ mordorQuestion, partyRef, timeline, uploadModalOpen, setUploadModalOpen }) => {
  const { effectiveMonth, effectiveYear, updateBinderItem, binderId } = useContext(MordorContext)

  const handleRequest = (req) => {
    req
      .then((result) => {
        partyjs.confetti(partyRef.current)

        updateBinderItem(mordorQuestion.id, timeline, result.body)

        setUploadModalOpen(false)
      })
      .catch((e) => {
        console.warn('Error while uploading doc', e)

        if (e.response && e.response.status == 400) {
          toast.error(e.response.body.error)
        } else {
          toast.error('Failed to upload document')
        }
      })

    toast.promise(req, { pending: 'Working...' })
  }

  const baseUrl = window.react_state.base_url || ''

  return (
    <>
      <Modal size="xl" show={uploadModalOpen} onHide={() => setUploadModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload {mordorQuestion.associated_document}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Effective Date: {effectiveMonth} / {effectiveYear}
          </h5>
          <em>{mordorQuestion.review_question}</em>
          <KismetDropzone
            postUrl={`${baseUrl}/api/mordor_binders/upload_question`}
            postParams={{
              question_id: mordorQuestion.id,
              id: binderId,
              effective_month: effectiveMonth,
              effective_year: effectiveYear,
            }}
            party={true}
            onRequest={handleRequest}
          />
          <table className="table table-sm table-hover table-bordered">
            <thead className="thead-light">
              <tr>
                <th>Reference Source</th>
                <td>{mordorQuestion.reference_source}</td>
              </tr>
              <tr>
                <th>Part</th>
                <td>{mordorQuestion.part}</td>
              </tr>
              <tr>
                <th>Category</th>
                <td>{mordorQuestion.category ? `${mordorQuestion.category}. ${mordorQuestion.category_name}` : ''}</td>
              </tr>
              <tr>
                <th>Section</th>
                <td>{mordorQuestion.section ? `${mordorQuestion.section}. ${mordorQuestion.section_name}` : ''}</td>
              </tr>
              <tr>
                <th>Programs</th>
                <td>
                  {mordorQuestion.matching_programs.map((mp) => (
                    <Badge success>{mp}</Badge>
                  ))}
                  <p>{mordorQuestion.programs.join(' / ')}</p>
                </td>
              </tr>
              <tr>
                <th>Policies & Procedures required?</th>
                <td>{mordorQuestion.policies_and_procedures_required == 'Yes' ? <Badge warning>Yes</Badge> : <Badge success>No</Badge>}</td>
              </tr>
              <tr>
                <th>HUD Citations</th>
                <td>{mordorQuestion.hud_citations}</td>
              </tr>
            </thead>
          </table>
          <p className="text-muted">
            <em>Question ID: #{mordorQuestion.id}</em>
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}
