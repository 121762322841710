import moment from 'moment'

export const dateMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

// Input format YYYY-MM-DD
export const prettyPrintDate = (date) => {
  if (!date) return ''
  return moment(date).format('M/D/YYYY')
}
