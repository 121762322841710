import React from 'react'

export const QuestionReferences = ({ questions }) => {
  const createModal = (q) => {
    return (
      <div className="modal fade" role="dialog" tabIndex={-1} id={`modal-mordor-question-${q.id}`}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">MORDOR Question {q.reference_source}</h5>
              <button className="close" aria-label="Close" data-dismiss="modal" type="button">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <table className="table table-sm table-hover table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Question order</th>
                    <td>{q.question_order}</td>
                  </tr>
                  <tr>
                    <th>Reference source</th>
                    <td>{q.reference_source}</td>
                  </tr>
                  <tr>
                    <th>Part</th>
                    <td>{q.part}</td>
                  </tr>
                  {/* TODO: PULL DATA FROM MORDORV1:: instead of just listing it twice*/}
                  <tr>
                    <th>Category</th>
                    <td>
                      {q.category}. {q.category_reference}
                    </td>
                  </tr>
                  <tr>
                    <th>Section</th>
                    <td>
                      {q.section}. {q.section}
                    </td>
                  </tr>
                  {/* </TODO> */}
                  <tr>
                    <th>Programs</th>
                    <td>{q.programs.join(' / ')}</td>
                  </tr>
                  <tr>
                    <th>Review question</th>
                    <td>{q.review_question}</td>
                  </tr>
                  <tr>
                    <th>Associated document</th>
                    <td>{q.associated_document}</td>
                  </tr>
                  <tr>
                    <th>Document storage level</th>
                    <td>{q.document_storage_level}</td>
                  </tr>
                  <tr>
                    <th>Document timeline</th>
                    <td>{q.document_storage_timeline}</td>
                  </tr>
                  <tr>
                    <th>Document upload count</th>
                    <td>{q.document_upload_count}</td>
                  </tr>
                  <tr>
                    <th>Document method</th>
                    <td>{q.document_method}</td>
                  </tr>
                  <tr>
                    <th>Report method</th>
                    <td>{q.report_method}</td>
                  </tr>
                  <tr>
                    <th>Document required?</th>
                    <td>{q.document_required}</td>
                  </tr>
                  <tr>
                    <th>HUD metadata</th>
                    <td>{q.hud_metadata}</td>
                  </tr>
                  <tr>
                    <th>Kismet metadata</th>
                    <td>{q.kismet_metadata}</td>
                  </tr>
                  <tr>
                    <th>Policies &amp; Procedures required?</th>
                    <td>{q.policies_and_procedures_required}</td>
                  </tr>
                  <tr>
                    <th>Listed on Addendum C?</th>
                    <td>{q.listed_on_addendum_c}</td>
                  </tr>
                  <tr>
                    <th>HUD citations</th>
                    <td>{q.hud_citations}</td>
                  </tr>
                </thead>
              </table>
              <p className="text-muted">
                <em>Question ID: {q.id}</em>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <>{questions.map((question) => createModal(question))}</>
}

export default QuestionReferences
