import React, { useMemo, useState } from 'react'
import { useEffectiveDate } from '../hooks/useEffectiveDate'
import { deblobReportRows } from '../mordor/mordor_helper'
import { prettyPrintDate } from '../DateHelper'
import { useKismetRequest } from '../hooks/useKismetRequest'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { Column } from 'primereact/column'
import HOC from '../HOC'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'

import { usePropertySelector } from '../hooks/usePropertySelector'

Chart.register(ArcElement, Tooltip, Legend)

const reports = [
  {
    name: 'Pending Recerts',
    doc_ref_id: '066028f71eb747259d997d426b86e22c',
    columns: [
      {
        name: 'Property Name',
        key: 'property_name',
      },
      {
        name: 'Building',
        key: 'building',
      },
      {
        name: 'Unit Number',
        key: 'unit_number',
      },
      {
        name: 'HH ID',
        key: 'hh_id',
      },
      {
        name: 'Tenant Name',
        key: 'tenant_name',
      },
      {
        name: 'Phone Number',
        key: 'phone_number',
      },
      {
        name: 'Last Annual',
        key: 'last_annual',
      },
      {
        name: 'Unsigned',
        key: 'unsigned',
      },
      {
        name: 'Recert Date',
        key: 'recert_date',
        date: true,
      },
      {
        name: 'Rental Agent',
        key: 'rental_agent',
      },
      {
        name: 'Funding Program',
        key: 'funding_program',
      },
      {
        name: 'Sched Out Date',
        key: 'sched_out_date',
        date: true,
      },
    ],
  },
  {
    name: 'Gross Potential Activity',
    doc_ref_id: '2cbd891f28fc40bfa0cdcde2dca3099e',
    columns: [
      {
        name: 'Property Name',
        key: 'property_name',
      },
      {
        name: 'Effective Date',
        key: 'effective_date',
        date: true,
      },
      {
        name: 'Unit Number',
        key: 'unit_number',
      },
      {
        name: 'Rent Billed',
        key: 'rent_billed',
        summable: true,
      },
      {
        name: 'Subsidy Billed',
        key: 'subsidy_billed',
        summable: true,
      },
      {
        name: 'Utility Reimbursement',
        key: 'utility_reimbursement',
        summable: true,
      },
      {
        name: 'Total Billed To AR',
        key: 'total_billed_to_ar',
        summable: true,
      },
      {
        name: 'Overage Excess Rent',
        key: 'overage_excess_rent',
        summable: true,
      },
      {
        name: 'Lost Rent',
        key: 'lost_rent',
        summable: true,
      },
      {
        name: 'Vacancy Expense',
        key: 'vacancy_expense',
        summable: true,
      },
      {
        name: 'Total Billed',
        key: 'total_billed',
        summable: true,
      },
      {
        name: 'Unit Gross Potential',
        key: 'unit_gross_potential',
        summable: true,
      },
      {
        name: 'Variance',
        key: 'variance',
        summable: true,
      },
      {
        name: 'Concessions',
        key: 'concessions',
        summable: true,
      },
      {
        name: 'Total Billed After Concessions',
        key: 'total_billed_after_concessions',
        summable: true,
      },
    ],
  },
  {
    name: '50059',
    doc_ref_id: 'e99a498d77c9427eb1be01e7c3592fcc',
    columns: [
      {
        name: 'Property Name',
        key: 'property_name',
      },
      {
        name: 'Unit Number',
        key: 'unit_number',
      },
      {
        name: 'Effective Date',
        key: 'effective_date',
        date: true,
      },
      {
        name: 'Tenant Name',
        key: 'tenant_name',
      },
      {
        name: 'Cert. Effective Date',
        key: 'cert_effective_date',
      },
      {
        name: 'Cert. Type',
        key: 'cert_type',
      },
      {
        name: 'Income Layer',
        key: 'income_layer',
      },
      {
        name: 'Extremely Low Income',
        key: 'extremely_low_income',
      },
      {
        name: 'Very Low or Low Income',
        key: 'very_low_or_low_income',
      },
      {
        name: 'Move In Date',
        key: 'move_in_date',
        date: true,
      },
      {
        name: 'Move Out Date',
        key: 'move_out_date',
        date: true,
      },
    ],
  },
]

const getRandomColorArray = (n) => {
  const colors = []
  for (let i = 0; i < n; i++) {
    colors.push(`rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`)
  }
  return colors
}

const colors = getRandomColorArray(100)

const WMPage = ({ properties }) => {
  properties = properties || []

  const { effectiveMonth, effectiveYear, effectiveDatePicker } = useEffectiveDate()
  const { selectedProperties, propertySelector } = usePropertySelector(
    properties.map((property) => ({
      name: property.name,
      id: property.id,
    }))
  )

  const propertyNames = useMemo(() => selectedProperties.map((property) => property.name), [selectedProperties])
  const [selectedReport, setSelectedReport] = useState(reports[0])

  const { data: blobbedReportRows } = useKismetRequest({
    url: '/api/washing_machine/property_mordor_report_rows',
    params: useMemo(
      () => ({
        effective_year: effectiveYear,
        effective_month: effectiveMonth,
        property_names: propertyNames,
        doc_ref_id: selectedReport.doc_ref_id,
      }),
      [effectiveYear, effectiveMonth, propertyNames, selectedReport]
    ),
    autoFetch: true,
    defaultValue: [],
  })

  const reportRows = useMemo(() => {
    return deblobReportRows(blobbedReportRows)
  }, [blobbedReportRows])

  const sumRow = useMemo(() => {
    return selectedReport.columns.reduce((acc, column) => {
      if (column.summable) {
        return {
          ...acc,
          [column.key]: reportRows.reduce((acc, row) => acc + parseFloat(row[column.key].replaceAll('(', '')), 0),
        }
      } else {
        return acc
      }
    }, {})
  }, [reportRows])

  return (
    <div>
      <h1>{selectedReport.name}</h1>

      <Dropdown value={selectedReport} onChange={(e) => setSelectedReport(e.value)} options={reports} optionLabel="name" placeholder="Select a report" />

      <div className="col-4">{propertySelector}</div>

      {/* Render donut if apropriate */}
      {!!Object.keys(sumRow).length && (
        <Doughnut
          style={{ maxHeight: '15rem' }}
          data={{
            labels: selectedReport.columns.filter((column) => column.summable).map((column) => column.name),
            datasets: [
              {
                label: '$',
                data: selectedReport.columns.filter((column) => column.summable).map((column) => sumRow[column.key]),
                backgroundColor: colors,
              },
            ],
          }}
        />
      )}

      {effectiveDatePicker}

      <div>
        <DataTable
          paginator
          rows={50}
          rowsPerPageOptions={[50, 100, 250]}
          value={reportRows}
          frozenValue={Object.keys(sumRow).length ? [sumRow] : undefined}
          tableStyle={{ minWidth: '50rem' }}
          scrollable
          scrollHeight="500px"
          removableSort
        >
          {selectedReport.columns.map((column) => (
            <Column field={column.key} header={column.name} sortable body={column.date ? (row) => prettyPrintDate(row.effective_date) : undefined} />
          ))}
        </DataTable>
      </div>
    </div>
  )
}

export default HOC(WMPage)
