import React from 'react'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import { ToastContainer } from 'react-toastify/dist/react-toastify'

export const HOC = (Component) => {
  return (props) => (
    <>
      <Component {...props} />
      <ToastContainer position="bottom-right" />
    </>
  )
}

export default HOC
